import React, {
  useState,
  useEffect,
  useCallback,
  FC
} from 'react';

import { useVersion, useDataProvider, fetchUtils } from 'react-admin';
import { useMediaQuery, Theme, Card, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Auth from '@aws-amplify/auth';

import CardWithIcon from '../common/CardWithIcon';
import DeviceIcon from '@material-ui/icons/PermIdentityOutlined';
import DeviceAddIcon from '@material-ui/icons/PersonAddOutlined';
import VolumeIcon from '@material-ui/icons/DataUsageOutlined';

import BarChart from '../common/BarChart';
import Table from './Table';
import { formatBytes } from './utils';
import { MapContainer, TileLayer } from 'react-leaflet';
import MarkerClusterMap from '../common/MarkerClusterMap'

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;
const dateToday = new Date().toISOString().slice(0, 10);

const Dashboard: FC = () => {
  const [state, setState] = useState();
  const version = useVersion();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.sendRequest('/stats')
      .then(res => setState(res && res.data));
  }, [version]);

  if (!state) return null;

  return (
    <>
      <div style={{marginTop: 10, ...styles.flex }}>
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <CardWithIcon
              to="/devices"
              icon={DeviceIcon}
              title='Total Devices'
              subtitle={state.devices.count}
              />
            <Spacer />
            <CardWithIcon
              to="/devices"
              icon={DeviceAddIcon}
              title='Added Today'
              subtitle={state.devices.today.count || '0'}
              />
          </div>
          <div style={styles.singleCol}>
            <BarChart
              title='Usage Trend'
              data={state.usage.byDate.map(item => ({
                key: item.date,
                value: item.bytes
              }))}
            />
          </div>
        </div>

        <div style={styles.rightCol}>
          <div style={styles.flex}>
            <CardWithIcon
              icon={VolumeIcon}
              title='Total Usage'
              subtitle={formatBytes(state.usage.bytes)}
              />
            <Spacer />
            <CardWithIcon
              icon={VolumeIcon}
              title='Usage Today'
              subtitle={formatBytes(state.usage.today.bytes)}
              />
          </div>
          <div style={styles.singleCol}>
            <Table
              title={'Top consumers'} 
              today={state.usage.today.byDevice.map(item => ({
                ...item,
                value: formatBytes(item.bytes),
              }))}
              all={state.usage.byDevice.map(item => ({
                ...item,
                value: formatBytes(item.bytes),
              }))}
              />
          </div>
        </div>
      </div>
      <Card>
        <MapContainer style={{height:450}}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterMap />
        </MapContainer>
      </Card>
    </>
  );
};

export default Dashboard;
