const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const k = 1024;

function formatBytes(bytes) {
  if (bytes === 0) return '0 Bytes';

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const dm = getDecimals(i);
  
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function getDecimals(i) {
	return i < 3 ? 0 : 1;
}

function getByteUnit(bytes) {
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return {
		unit: sizes[i],
		divisor: Math.pow(k, i),
		decimals: getDecimals(i)
	};
}

function getTodayDate() {
	const date = new Date()
	return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
}

export {
	formatBytes,
	getByteUnit,
	getTodayDate
}