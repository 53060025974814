import * as React from 'react';
import * as ra from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Menu from './Menu';
import logo from './logo.png';

const Logo = props => (
  <>
    PVPC
  </>
);

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

const AppBar = props => {
  const classes = useStyles();
  return (
    <ra.AppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Logo />
      <span className={classes.spacer} />
    </ra.AppBar>
  );
};

const theme = {
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        color: '#808080',
        backgroundColor: '#fff',
      }
    }
  }
};

const Sidebar = props => <ra.Sidebar {...props} size={200} />;

export default props => {
  return (
    <ra.Layout
      {...props}
      appBar={AppBar}
      sidebar={Sidebar}
      menu={Menu}
      theme={theme}
    />
  );
};
