import * as React from 'react';
import { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import { useDataProvider } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
  },
  cost: {
    marginRight: '1em',
    color: theme.palette.text.primary,
  },
}));

const Table: FC = props => {
  const classes = useStyles();
  const [ series, setSeries ] = React.useState('today');
  const [ deviceNames, setDeviceNames ] = React.useState();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    dataProvider.getMany('devices', { 
      ids: props[series].map(item => item.id)
    }).then(res => {
      setDeviceNames(res.data.reduce((memo, device) => {
        memo[device.id] = device.name;
        return memo;
      }, {}))
    })
  }, [dataProvider, series]);

  if (!deviceNames) return <p>Loading...</p>;

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <>
            Top consumers
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={series}
            onChange={ev => setSeries(ev.target.value)}
            style={{float: 'right', marginTop: -3}}
            >
            <MenuItem value='today'>Today</MenuItem>
            <MenuItem value='all'>All time</MenuItem>
          </Select>
          </>
        }
        />
      <List dense={true}>
        {props[series]
          .filter(row => deviceNames[row.id])
          .map(row => (
          <ListItem
            key={row.id}
            button
            component={Link}
            to={`/devices/${row.id}/show`}
            >
            <ListItemText
              primary={deviceNames ? deviceNames[row.id] : row.id}
            />
            <ListItemSecondaryAction>
              <span className={classes.cost}>
                {row.value}
              </span>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default Table;
