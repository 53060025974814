import * as React from 'react';
import { FC } from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { getByteUnit, formatBytes } from '../dashboard/utils';

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

const _BarChart: FC = ({ title, data }) => {
  if (!data) return null;

  const max = Math.max(...data.map(d => d.value));
  const { divisor, unit, decimals } = getByteUnit(max);
  
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <ResponsiveContainer width="100%" height={335}>
          <BarChart
            data={data.map(d => ({
              ...d,
              value: parseFloat((d.value / divisor).toFixed(decimals)),
              bytes: d.value
            }))
            }>
            <XAxis
              dataKey="key"
              name="Date"
            />
            <YAxis dataKey="value" name="Volume" unit={unit} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              formatter={(value, name, { payload }) => formatBytes(payload.bytes)}
            />
            <Bar dataKey="value" fill="#3f51b5" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default _BarChart;
